import LOGO from './assets/elite logo.png';
import BackGround from './assets/group-91216@3x.png';
import EliteImage from './elite.png';
import EliteLogo from './assets/elite-logo-d-3773943634811-d-39351-1.png';
import EliteLogoBottom from './assets/group-91193.png';
import SendImage from './assets/send-1.png';
import K2Image from './k2.png';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowUp, Loader2Icon, LoaderIcon } from 'lucide-react';
import Bubble from './Bubble2';
import BubbleHelp from './BubbleHelp';
// import { useSession } from 'next-auth/react';
// import { signIn } from 'next-auth/react';
import axiosinstance, { BASE_URL } from './axiosInterceptorInstance';
import { Button } from './button';
import { useAuth } from './AuthContext';
import TextareaWithButton from './textareabutton';
import Loading from './Loading';
import Error from './Error';
import { toast } from 'react-toastify';
import BubbleModalEvent from './BubbleModal';
import { replacementRules, tableSchema } from './replacement';
import { useLocation, useNavigate } from 'react-router-dom';

const contentGatheringData = `an agent will gather the data you need from the Database`;
export default function Home() {
  const { user, customer, isLoadingLogin, isLoggedIn, error } = useAuth(); // Use custom hook

  const [message, setMessage] = useState('');
  const [items, setItems] = useState<
    Array<{
      role: string;
      prompt: string;
      flow?: boolean;
      step?: number;
      message_id?: string;
      isAnimatingChatLoad?: boolean;
    }>
  >([]);
  const [sidechat, setSidechat] = useState(false);

  const [isUserSetupPending, setIsUserSetupPending] = useState(false);
  const [history, setHistory] = useState<any[]>([]);
  const [showFlow, setShowFlow] = useState(false);
  const refs = useRef({});

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSend();
    }

    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      setMessage((prev) => prev + '\n');
    }
  };

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messagesEndRef2 = useRef<HTMLDivElement | null>(null);

  const [canAutoScroll, setCanAutoScroll] = useState(true);
  const [canAutoScroll2, setCanAutoScroll2] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [chatActive, setChatActive] = useState(true);
  const [chatDisable, setChatDisable] = useState(false);
  const [canSend, setCanSend] = useState(true);
  const [placeHolder, setPlaceHolder] = useState('Ask me anything...');
  const [gatheringData, setGatheringData] = useState({ loading: false });
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const sideChatContainerRef = useRef<HTMLDivElement | null>(null);
  const showFlowForThisMessage = useRef(false);
  const [selectedFlow, setSelectedFlow] = useState(-1);
  const [isVisibleBookDemo, setIsVisibleBookDemo] = useState(false);

  const getUserHistory = async () => {
    const chatHistory = await axiosinstance.get('/user_chat_history', {
      headers: {
        Authorization: `Bearer ${user.access_token}`
      }
    });

    setItems(
      chatHistory.data.map((elem: any) => ({
        role: 'K2',
        prompt: elem.content
      }))
    );
  };

  const eventSourceRef = useRef<EventSource | null>(null);

  const startStreaming = () => {
    try {
      if (user) {
        const access_token = user.access_token;

        if (access_token) {
          // const url = `https://360ai.k2view.com:5000/stream?token=${encodeURIComponent(access_token)}`;
          const url = `${BASE_URL}/stream?token=${encodeURIComponent(access_token)}`;

          eventSourceRef.current = new EventSource(url);

          eventSourceRef.current.onmessage = (event) => {
            const newMessage = JSON.parse(event.data);
            if (newMessage.content === 'setup_done') {
              setIsUserSetupPending(false);
              eventSourceRef.current?.close();
              return;
            } else if (newMessage.endStream) {
              eventSourceRef.current?.close();
              return;
            } else if (!newMessage.content) {
              return;
            }
            if (newMessage.content.includes(contentGatheringData)) {
              setGatheringData({ loading: true });
            }

            const matchedRule = replacementRules.find((rule) =>
              newMessage.content.includes(rule.includes)
            );

            if (matchedRule) {
              newMessage.content = matchedRule.replacement;
            }
            setHistory((prevMessages) => [
              ...prevMessages,
              { ...newMessage, time: new Date().toLocaleString() }
            ]);

            if (newMessage.content === '{111}') {
              // Handle {111}: Gather data from the database
              console.log('Detected {111}: Gathering data from the database');
              showFlowForThisMessage.current = true;
            } else if (newMessage.content === '{222}') {
              // Handle {222}: Ready to answer the question
              console.log('Detected {222}: Generating answer for the user');
              showFlowForThisMessage.current = true;
              //show the flow
            }
          };

          eventSourceRef.current.onerror = (error) => {
            console.error('EventSource failed:', error);
            setIsUserSetupPending(false);
            eventSourceRef.current?.close();
          };
        }
      }
    } catch (error) {
      console.error('Error setting auth token', error);
    }
  };

  const eventSourceRef2 = useRef<EventSource | null>(null);

  const startStreaming2 = (message: string) => {
    try {
      if (user) {
        const access_token = user.access_token;

        if (access_token) {
          const url = `${BASE_URL}/message?token=${encodeURIComponent(access_token)}&message=${encodeURIComponent(message)}`;
          eventSourceRef2.current = new EventSource(url);

          eventSourceRef2.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            let msg = data.content;
            setGatheringData({ loading: false });
            if (data.endStream) {
              eventSourceRef2.current?.close();
              setCanSend(true);
              //show the flow on endStream and there is a {111}
              if (showFlowForThisMessage.current) {
                showFlowForThisMessage.current = false;

                setItems((prev) => {
                  return prev.map((item, index) => {
                    if (index === prev.length - 1) {
                      return {
                        ...item,
                        flow: true,
                        step: -1,
                        isAnimatingChatLoad: true
                      };
                    }
                    return item;
                  });
                });

                setTimeout(() => {
                  setItems((prev) => {
                    return prev.map((item, index) => {
                      if (index === prev.length - 1) {
                        return {
                          ...item,
                          flow: true,
                          step: 0,
                          isAnimatingChatLoad: false
                        };
                      }
                      return item;
                    });
                  });
                }, 2000);
              }
            } else {
              setIsLoading(false);
              setItems((prev) => {
                const newItems = [...prev];
                if (newItems.length === 0 || newItems[newItems.length - 1].role !== 'K2') {
                  newItems.push({
                    role: 'K2',
                    prompt: msg,
                    message_id: data.message_id
                  });
                } else {
                  newItems[newItems.length - 1].prompt += msg;
                }
                return newItems;
              });
            }
          };

          eventSourceRef2.current.onerror = (error) => {
            console.error('EventSource error:', error);
            if (eventSourceRef2.current?.readyState === EventSource.CLOSED) {
              // Ignore the error if the connection is already closed
              return;
            }
            setItems((prev) => [
              ...prev,
              {
                role: 'K2',
                prompt: 'Error in server'
              }
            ]);
            toast.error('Error or token expired, please start a new chat');
            setChatActive(false);
            eventSourceRef2.current?.close();
            setIsLoading(false);
          };

          eventSourceRef2.current.onopen = () => {
            // console.log('EventSource opened:', eventSourceRef2.current);
            setCanAutoScroll(true);
          };
        }
      }
    } catch (error) {
      console.error('Error setting auth token', error);
    }
  };

  //only set the book a demo to true if one of the messages have step >= 2
  useEffect(() => {
    const hasStepThree = items.some((item) => item?.step !== undefined && item.step >= 2);
    setIsVisibleBookDemo(hasStepThree);
  }, [items]);

  useEffect(() => {
    if (items.length > 10) {
      setChatDisable(true);
      setPlaceHolder('The chat session has ended.');
    }
  }, [items]);

  // useEffect(() => {
  //   // Ensure `items` and `history` are not empty
  //   if (items.length > 0 && history.length > 0) {
  //     setItems((prevItems) => {
  //       return prevItems.map((item, index) => {
  //         // Update the historyIndex only for the last item in `items`
  //         if (index === prevItems.length - 1) {
  //           return {
  //             ...item,
  //             historyIndex: history.length - 1 // Set the last index of `history`
  //           };
  //         }
  //         return item; // Leave other items unchanged
  //       });
  //     });
  //   }
  // }, [items, history]);

  useEffect(() => {
    // Logic to handle component mount and unmount
    return () => {
      // Ensure to close the EventSource when the component unmounts
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setIsUserSetupPending(true);
      getUserHistory();
      startStreaming();
    }
  }, [isLoggedIn]);

  const handleScroll = useCallback(() => {
    // Check if the chat container scroll is at the bottom
    if (chatContainerRef.current) {
      const isScrolledToBottom =
        chatContainerRef.current.scrollHeight - chatContainerRef.current.clientHeight <=
        chatContainerRef.current.scrollTop + 1;
      setCanAutoScroll(isScrolledToBottom);
    }
  }, []);

  const handleScroll2 = useCallback(() => {
    // Check if the chat container scroll is at the bottom
    if (sideChatContainerRef.current) {
      const isScrolledToBottom =
        sideChatContainerRef.current.scrollHeight - sideChatContainerRef.current.clientHeight <=
        sideChatContainerRef.current.scrollTop + 1;
      setCanAutoScroll2(isScrolledToBottom);
    }
  }, []);

  useEffect(() => {
    // Attach scroll event listener
    const chatContainer = chatContainerRef.current;
    const sideChatContainer = sideChatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
    }

    if (sideChatContainer) {
      sideChatContainer.addEventListener('scroll', handleScroll2);
    }

    return () => {
      // Clean up the event listener
      if (chatContainer) chatContainer.removeEventListener('scroll', handleScroll);
      if (sideChatContainer) sideChatContainer.removeEventListener('scroll', handleScroll2);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (canAutoScroll) {
      messagesEndRef.current?.scrollIntoView({
        behavior: 'instant'
      });
    }
  }, [items, canAutoScroll]);

  useEffect(() => {
    if (!chatActive) {
      messagesEndRef.current?.scrollIntoView({
        behavior: 'instant'
      });
    }
  }, [chatActive]);

  useEffect(() => {
    if (canAutoScroll2) {
      messagesEndRef2.current?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [canAutoScroll2, history]);

  const runChangeHelloOnce = useRef(true);
  useEffect(() => {
    // Only change the first message once
    if (items.length === 1 && runChangeHelloOnce.current) {
      setItems([
        {
          role: 'K2',
          prompt: `
  Hi! I am Elite Assistant, a chatbot developed by K2view that showcases the power of grounding LLMs with real-time enterprise data.
  
  **Elite** is a fictitious telco company, providing mobile phone, internet, and cable TV services. You’re David Smith, an Elite customer.
  
  You can ask me any questions around billing, service plans, outages, and so on…like “what are my outstanding bills”, “why is my home internet not working”, or “what plan do I have for Amazon Prime”.
  
  I’ll guide you through some behind-the-scenes processes that make the bot’s responses both accurate and fast.
          `.trim()
        }
      ]);
      runChangeHelloOnce.current = false;
    }
  }, [items]);

  const runOnceTableSchema = useRef(true);
  useEffect(() => {
    //only change first message
    if (history.length && runOnceTableSchema.current) {
      //position 2 in the array
      setHistory((prevMessages) => {
        const newItem = { type: 'K2', content: tableSchema, time: new Date().toLocaleString() };

        return [prevMessages[0], newItem, ...prevMessages.slice(1)];
      });
      runOnceTableSchema.current = false;
    }
  }, [history]);

  const onSend = async () => {
    if (isLoading) return;
    if (!message.trim()) return;
    if (!isLoggedIn) return;
    if (!canSend) return;

    startStreaming();

    const save = !message.includes('#####');

    try {
      if (save) {
        setIsLoading(true);
        setItems((prev) => [
          ...prev,
          {
            role: message.includes('###') ? 'K2' : 'human',
            prompt: message
          }
        ]);
      }

      setMessage('');

      //disable chat
      setCanSend(false);
      startStreaming2(message);
      // Send the message to the server
    } catch (error) {
      setItems((prev) => [
        ...prev,
        {
          role: 'K2',
          prompt: 'Error in server'
        }
      ]);

      toast.error('Error or token expired, please start a new chat');
      setChatActive(false);
      setChatDisable(true);
      setCanSend(false);
    } finally {
      setIsLoading(false);
    }
  };

  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const scrollToElement = (id, offset = 0) => {
    setSidechat(true);

    setTimeout(() => {
      if (refs.current[id]) {
        const targetElement = refs.current[id];
        const parentElement = targetElement.parentElement;

        if (parentElement) {
          // Get the element's offset relative to the parent
          const elementOffsetTop = targetElement.offsetTop;

          // Scroll to the element's position and adjust down by 100px
          parentElement.scrollTo({
            top: elementOffsetTop + offset, // Adjust by 100px
            behavior: 'smooth'
          });
        }

        setSelectedFlow(id);
      }
    }, 500);
  };

  const getLastReflectionByIndex = useCallback(
    (currentMessageId) => {
      for (let i = history.length - 1; i >= 0; i--) {
        if (
          history[i].message_id === currentMessageId &&
          history[i].content.includes(
            "Please reflect on the customer's issue and the data you have,"
          )
        ) {
          return i;
        }
      }
      return -1;
    },
    [history]
  );

  const getLastAnswerIndex = useCallback(
    (currentMessageId) => {
      for (let i = history.length - 1; i >= 0; i--) {
        if (history[i].message_id === currentMessageId && history[i].content.includes('{222}')) {
          return i + 2 < history.length ? i + 2 : -1;
        }
      }
      return -1;
    },
    [history]
  );
  const hasMovedItemRef = useRef(false);

  //to make sql queries not in parallel
  useEffect(() => {
    if (history.length > 8 && !hasMovedItemRef.current) {
      setHistory((prev) => {
        const updatedHistory = [...prev];

        // Remove the item at index 4
        const [movedItem] = updatedHistory.splice(4, 1);

        // Insert the removed item at index 7
        updatedHistory.splice(6, 0, movedItem);

        return updatedHistory;
      });

      hasMovedItemRef.current = true; // Mark as performed
    }
  }, [history]);

  const [fullURL, setFullURL] = useState('https://www.k2view.com/');
  const location = useLocation();

  useEffect(() => {
    // Extract query parameters
    const searchParams = new URLSearchParams(location.search);
    const paramsObject: Record<string, string> = {};
    let backToValue: string | null = null;

    for (const [key, value] of searchParams.entries()) {
      if (key === 'backTo') {
        // Save "backTo" parameter separately
        backToValue = value;
      } else {
        // Add other parameters to paramsObject
        paramsObject[key] = value;
      }
    }

    // Save "backTo" parameter in session storage if it exists
    if (backToValue) {
      sessionStorage.setItem('backTo', backToValue);
    }

    // Extract GUID from the path
    const pathArray = location.pathname.split('/');
    const guid = pathArray[1]; // Assuming GUID is the first segment after "/"

    if (guid) {
      // Save GUID in session storage
      sessionStorage.setItem('guid', guid);
    }

    sessionStorage.setItem('params', JSON.stringify(paramsObject));

    // Build the full URL
    const queryString = new URLSearchParams(paramsObject).toString();
    const baseURL = backToValue || 'https://www.k2view.com';
    const fullURL = queryString ? `${baseURL}?${queryString}` : baseURL;
    setFullURL(fullURL);
    sessionStorage.setItem('fullURL', fullURL);
    // Remove GUID and query parameters from the URL without navigating
    const newPath = '/';
    window.history.replaceState(null, '', newPath);
  }, [location]);

  return (
    <div
      className="relative w-screen h-screen flex bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${BackGround})` }}>
      {isLoadingLogin && <Loading message="Starting Session..." />}
      <div className={'p-1 fixed top-[70px] left-[50px]'}>
        <img src={EliteLogo} alt="LLM" />
      </div>
      {error && <Error message="Unauthorized" />}

      <div className={`flex flex-col flex-shrink-0 justify-end items-end w-full `}>
        <div
          className={`flex flex-col justify-between rounded-xl pt-[70px] bg-white shadow relative z-10 m-10 mt-20 mb-32 overflow-hidden w-4/12 mr-[800px]`}
          style={{ height: 'calc(100% - 100px)' }}>
          {isUserSetupPending && <Loading message="Generating User Info..." />}
          <div className="absolute top-0 left-0 w-full h-[70px] flex p-2 px-4 bg-[#0b1b87] items-center justify-start gap-4">
            <div className="w-8 h-8">
              <div className={'rounded-full bg-gray-100 border p-1'}>
                <img src={EliteImage} alt="LLM" />
              </div>
            </div>
            <div className="text-[22px] font-semibold text-white  text-center">
              Hi! How can we help you today?
            </div>
          </div>
          <div
            className="flex flex-col pt-10 overflow-auto h-full p-4 scrollbarStyled "
            ref={chatContainerRef}>
            {items.map((el, index) => (
              <Bubble
                key={index}
                flow={el.flow}
                isAnimatingChatLoad={el.isAnimatingChatLoad}
                step={el.step}
                index={index}
                message_id={el.message_id}
                message={{
                  role: el.role,
                  prompt: el.prompt
                }}
                mainchat={true}
                scrollToElement={scrollToElement}
                setItems={setItems}
                getLastReflectionByIndex={getLastReflectionByIndex}
                getLastAnswerIndex={getLastAnswerIndex}
              />
            ))}

            {isLoading && (
              <Bubble
                message={{
                  role: 'K2',
                  prompt: 'Loading...'
                }}
                mainchat={true}
              />
            )}

            {gatheringData.loading && (
              <div className="flex items-center ml-[40px]">
                <div role="status">
                  {/* {gatheringData.success ? <svg className="w-4 h-4 me-2 text-green-500 dark:text-green-400 flex-shrink-0 animate-appear" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg> : */}
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 me-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
                Gathering data about the customer...
              </div>
            )}

            {!chatActive && (
              <div className="bg-red-500 text-white text-center p-2 mt-2 rounded sticky bottom-0 left-0">
                The chat session has ended.
              </div>
            )}
            <div ref={messagesEndRef} style={{ width: '100%' }} />
          </div>
          <div className="flex px-2 py-4 justify-center items-center gap-3">
            <TextareaWithButton
              autoComplete="off"
              value={message}
              onKeyDown={handleKeyPress}
              onChange={handleInputChange}
              name="message"
              placeholder={placeHolder}
              className="w-full flex items-center resize-none overflow-hidden bg-background"
              buttonText={''}
              disabled={isLoading || !chatActive || chatDisable ? true : false}
              onButtonClick={onSend}>
              <div className=" bg-blue-500 hover:bg-blue-700 text-white font-bold flex items-center justify-center p-2 rounded-full">
                <div>
                  <img className="h-5 w-5" src={SendImage} alt="LLM" />
                </div>
              </div>
            </TextareaWithButton>
          </div>
        </div>
      </div>
      <div
        ref={sideChatContainerRef}
        className={` fixed top-0 right-0 ${sidechat ? '' : 'translate-x-full'}  h-[calc(100%-64px)] w-[700px] bg-white z-10 flex-grow-0 flex-shrink-0 basis-[700px] shadow-2xl ease-in-out duration-300 from-he flex flex-col gap-3 items-start `}>
        <div className="h-full scrollbarStyled overflow-auto p-4">
          {history.map((el, i) => (
            <React.Fragment key={i}>
              <Bubble
                ref={(node) => (refs.current[i] = node)}
                message={{
                  role: el.type,
                  prompt: el.content,
                  time: el.time,
                  prompt_history: el.prompt_history
                }}
                divider={el.type === 'human' ? true : false}
                selectedFlow={selectedFlow}
                index={i}
                // markdownDisabled={true}
              />
            </React.Fragment>
          ))}
        </div>

        <div ref={messagesEndRef2} style={{ width: '100%' }} />
      </div>
      <div
        className={`fixed left-0 bottom-0 bg-[#0086fe] w-full h-[65px] flex items-center justify-evenly transition-transform duration-500 translate-y-full ${
          isVisibleBookDemo ? 'animate-fadeinbottomtop' : ''
        }`}>
        <img src={EliteLogoBottom} alt="LLM" />
        <span className="block text-lg font-normal leading-tight text-white ">
          <span className="font-bold">Get Your Data GenAI-ready </span>
          <span className="uppercase">U</span>se your enterprise data to power up your GenAI apps
        </span>
        <a
          href={fullURL}
          target="_blank"
          rel="noopener noreferrer"
          className="h-[32px] mt-[1px] rounded-[25px] border border-[#fbfbfb] bg-[#fbfbfb] flex items-center justify-center p-3 hover:ring-2 hover:border-black">
          <span className="font-roboto text-[16px] font-medium text-center text-black ">
            Book a Demo
          </span>
        </a>
      </div>
    </div>
  );
}

// export async function getServerSideProps({ params }: any) {
//   // Fetch data based on params.id
//   const res = await fetch(`http://localhost:5000/session`);
//   const session = await res.json();
//   console.log(session)
//   return {
//     session
//   };
// }
