import './App.css';
import React from 'react';
import Provider from './Provider';
import Page from './page';
import { BrowserRouter } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import BackGround from './assets/group-91216@3x.png';
import './index.css';
import K2vImage from './assets/group-146@3x.png';

function App() {
  return (
    <BrowserRouter>
      <Provider>
        <div className="App">
          <BrowserView>
            <Page />
          </BrowserView>
          <MobileView>
            <div
              className="relative w-screen h-screen flex bg-cover bg-center bg-no-repeat justify-center items-start box-border"
              style={{
                backgroundImage: `url(${BackGround})`,
                padding: '60px'
              }}>
              <div
                style={{
                  width: '100%',
                  height: '512px',
                  borderRadius: '9px',
                  boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
                  backgroundColor: '#ffffff',
                  padding: '60px 26px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <img src={K2vImage} alt="LLM" style={{ height: '42px' }} />
                <div
                  style={{
                    margin: '32.5px 0 6px',
                    fontSize: '21px',
                    fontWeight: 'bold',
                    lineHeight: '1.22',
                    textAlign: 'center',
                    color: '#000'
                  }}>
                  Grounding GenAI with enterprise data
                </div>
                <div
                  style={{
                    margin: '6px 16px 19px 15px',
                    fontSize: '18px',
                    fontWeight: 'normal',

                    textAlign: 'center',
                    color: '#0086fe'
                  }}>
                  GenAI Chatbot Product Tour
                </div>
                <div
                  style={{
                    margin: '19px 16px 0 15px',
                    fontSize: '18px',
                    fontWeight: 'normal',
                    textAlign: 'center',
                    color: '#000'
                  }}>
                  This live GenAI chatbot product tour is best experienced on a desktop for optimal
                  performance and usability.
                </div>
              </div>
            </div>
          </MobileView>
        </div>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
