import React from 'react';
import MarkDownComp from './MarkDown';
import EliteImage from './elite.png';
import K2Image from './k2.png';
interface MessageProps {
  type: string;
  content: string;
  time?: string;
  showHistoryButton?: boolean;
  openHistory?: () => void;
  markdownDisabled?: boolean;
  mainchat?: boolean;
}
const Message = ({
  type,
  content,
  time,
  showHistoryButton,
  openHistory,
  markdownDisabled,
  mainchat
}: MessageProps) => {
  return (
    <div
      className={`flex gap-3 my-1 text-gray-600 text-sm w-full ${
        type === 'human' ? 'flex-row-reverse text-right' : 'flex-row text-left'
      }`}>
      {type === 'human' && (
        <div className="w-8 h-8">
          <div className="rounded-full bg-gray-100 border p-1">
            <svg
              stroke="none"
              fill="black"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
            </svg>
          </div>
        </div>
      )}
      {(type === 'LLM' || type === 'ai') && (
        <div className="w-8 h-8">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17 34c9.389 0 17-7.611 17-17S26.389 0 17 0 0 7.611 0 17s7.611 17 17 17z"
              fill="#8842E7"
              fill-opacity=".18"
            />
            <path
              d="M5.799 20.851c5.803 0 7.33 7.318 7.33 7.318s1.526-7.318 7.33-7.318c-5.804 0-7.33-7.317-7.33-7.317s-1.527 7.317-7.33 7.317zM12.76 10.04c3.585.776 3.859 4.24 3.859 4.24s.26-3.464 3.86-4.24c-3.586-.776-3.86-4.24-3.86-4.24s-.26 3.464-3.86 4.24zM18.139 15.46a6.232 6.232 0 0 1 5.01 5.391 6.248 6.248 0 0 1 5.012-5.39 6.232 6.232 0 0 1-5.011-5.392 6.248 6.248 0 0 1-5.011 5.391z"
              fill="#8842E7"
            />
          </svg>
        </div>
      )}
      {(type === 'K2' || type === 'system') && (
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17 34c9.389 0 17-7.611 17-17S26.389 0 17 0 0 7.611 0 17s7.611 17 17 17z"
            fill="#fff"
          />
          <path
            d="M16.735 1a16.727 16.727 0 0 0-7.843 1.917C8.34 3.208 8 3.767 8 4.372v10.76c0 .1.041.192.115.259a.39.39 0 0 0 .259.098c.094 0 .179-.032.25-.095l5.78-5.106a.49.49 0 0 1 .325-.123h2.988a.5.5 0 0 1 .474.302.44.44 0 0 1-.11.483l-4.259 4.116c-.227.22-.416.45-.374.757.011.09.048.176.094.254 1.656 2.774 2.89 4.312 5.082 4.833.877.153 1.686.11 2.435-.12a2.95 2.95 0 0 0 .803-.393c.562-.383 1.408-1.05 2.542-2.214.636-.652 1.257-1.63 1.503-2.119.21-.416.329-.827.329-1.233 0-.59-.21-1.048-.627-1.363-.415-.315-1.03-.476-1.83-.476-.667 0-1.278.124-1.815.369a3.433 3.433 0 0 0-1.12.815.442.442 0 0 1-.554.08l-1.843-1.142a.414.414 0 0 1-.192-.281.393.393 0 0 1 .086-.316c.56-.673 1.29-1.22 2.174-1.626 1.04-.48 2.249-.723 3.592-.723 1.126 0 2.125.18 2.971.534.844.354 1.51.86 1.978 1.505.468.645.706 1.415.706 2.29 0 .79-.175 1.543-.522 2.237-.345.694-.774 1.354-1.77 2.387l-1.281 1.474c-.053.04-.06.157-.047.188.045.109.158.193.344.193h4.697c.718 0 1.343-.481 1.487-1.157.212-1 .326-2.035.33-3.095C33.03 8.03 25.776 1.015 16.735 1z"
            fill="#1483F3"
          />
          <path
            d="M19.801 24.459c-1.526.013-3.37-.473-5.002-1.84a6.76 6.76 0 0 1-.875-.904l-2.635-3.138a.37.37 0 0 0-.538-.036L8.64 20.52a.388.388 0 0 0-.123.282v3.164a.497.497 0 0 1-.5.494H5.537a.497.497 0 0 1-.5-.494V7.315c0-.296-.378-.429-.562-.197A15.804 15.804 0 0 0 1 16.844C.906 25.591 8.199 32.943 17.034 33a16.167 16.167 0 0 0 13.877-7.64.595.595 0 0 0-.513-.904l-10.597.002z"
            fill="#1483F3"
          />
        </svg>
      )}

      <p
        className={`flex flex-col justify-start w-full gap-1 mt-[5px] ${
          type === 'human' ? 'items-end text-right' : 'items-start'
        }`}>
        <span className="flex font-bold text-gray-700">
          {type === 'human'
            ? 'You'
            : type === 'LLM'
              ? 'LLM Response'
              : mainchat
                ? 'Elite Assistant'
                : 'GenAI Data Fusion'}{' '}
        </span>

        <div
          className={`ml-1 w-[80%] overflow-auto pb-2 ${type === 'human' ? 'text-right' : 'text-left'}`}>
          {markdownDisabled ? (
            <div className=" whitespace-pre-line">
              {content.replace(/^\s/, '').replace(/\s$/, '')}
            </div>
          ) : (
            <MarkDownComp text={content} />
          )}
        </div>

        {/* <span>
          <div className="text-sm ">{time}</div>
        </span> */}
      </p>
    </div>
  );
};
export default Message;
