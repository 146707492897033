import { Expand } from 'lucide-react';
import React from 'react';
import ExpandableContent from './ExpandableContent';

export const data1 = [
  {
    INVOICEID: 2760,
    SUBSCRIBERID: 144,
    DUEDATE: '2024-01-29',
    TOTALAMOUNT: 8.09,
    PAYMENTSTATUS: 'Due',
    OUTAGEID: 4701,
    OutageDateTime: '2024-02-27 22:29:31.184',
    SERVICE: 'Home Internet',
    OutageStatus: 'Active'
  },
  {
    INVOICEID: 2763,
    SUBSCRIBERID: 144,
    DUEDATE: '2024-02-29',
    TOTALAMOUNT: 8.09,
    PAYMENTSTATUS: 'Due',
    OUTAGEID: 4701,
    OutageDateTime: '2024-02-27 22:29:31.184',
    SERVICE: 'Home Internet',
    OutageStatus: 'Active'
  },
  {
    INVOICEID: 2765,
    SUBSCRIBERID: 144,
    DUEDATE: '2024-03-29',
    TOTALAMOUNT: 8.09,
    PAYMENTSTATUS: 'Due',
    OUTAGEID: 4701,
    OutageDateTime: '2024-02-27 22:29:31.184',
    SERVICE: 'Home Internet',
    OutageStatus: 'Active'
  },
  {
    INVOICEID: 2812,
    SUBSCRIBERID: 149,
    DUEDATE: '2024-03-29',
    TOTALAMOUNT: 62.99,
    PAYMENTSTATUS: 'Due',
    OUTAGEID: null,
    OutageDateTime: null,
    SERVICE: null,
    OutageStatus: null
  }
];

export const data2 = [
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 144,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Home Internet',
    SUBSCRIPTIONNAME: 'Fiber-optic',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 79.99,
    DISCOUNTCODE: null,
    DEVICEID: 144,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '59708978 702010 5',
    WARRANTYENDDATE: '2031-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2738,
    INVOICEDATE: '2023-05-29',
    DUEDATE: '2023-06-29',
    TOTALAMOUNT: 8.09,
    TAXAMOUNT: 0.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-06-03',
    PAYMENTMETHOD: 'Samsung Pay'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 144,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Home Internet',
    SUBSCRIPTIONNAME: 'Fiber-optic',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 79.99,
    DISCOUNTCODE: null,
    DEVICEID: 144,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '59708978 702010 5',
    WARRANTYENDDATE: '2031-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2742,
    INVOICEDATE: '2023-06-29',
    DUEDATE: '2023-07-29',
    TOTALAMOUNT: 8.09,
    TAXAMOUNT: 0.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-07-01',
    PAYMENTMETHOD: 'Direct Debit'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 144,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Home Internet',
    SUBSCRIPTIONNAME: 'Fiber-optic',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 79.99,
    DISCOUNTCODE: null,
    DEVICEID: 144,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '59708978 702010 5',
    WARRANTYENDDATE: '2031-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2744,
    INVOICEDATE: '2023-07-29',
    DUEDATE: '2023-08-29',
    TOTALAMOUNT: 8.09,
    TAXAMOUNT: 0.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-08-13',
    PAYMENTMETHOD: 'Credit Card'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 144,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Home Internet',
    SUBSCRIPTIONNAME: 'Fiber-optic',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 79.99,
    DISCOUNTCODE: null,
    DEVICEID: 144,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '59708978 702010 5',
    WARRANTYENDDATE: '2031-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2748,
    INVOICEDATE: '2023-08-29',
    DUEDATE: '2023-09-29',
    TOTALAMOUNT: 8.09,
    TAXAMOUNT: 0.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-09-09',
    PAYMENTMETHOD: 'Google Pay'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 144,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Home Internet',
    SUBSCRIPTIONNAME: 'Fiber-optic',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 79.99,
    DISCOUNTCODE: null,
    DEVICEID: 144,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '59708978 702010 5',
    WARRANTYENDDATE: '2031-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2751,
    INVOICEDATE: '2023-09-29',
    DUEDATE: '2023-10-29',
    TOTALAMOUNT: 8.09,
    TAXAMOUNT: 0.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-10-05',
    PAYMENTMETHOD: 'Cash App'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 144,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Home Internet',
    SUBSCRIPTIONNAME: 'Fiber-optic',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 79.99,
    DISCOUNTCODE: null,
    DEVICEID: 144,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '59708978 702010 5',
    WARRANTYENDDATE: '2031-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2754,
    INVOICEDATE: '2023-10-29',
    DUEDATE: '2023-11-29',
    TOTALAMOUNT: 8.09,
    TAXAMOUNT: 0.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-11-08',
    PAYMENTMETHOD: 'PayPal'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 144,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Home Internet',
    SUBSCRIPTIONNAME: 'Fiber-optic',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 79.99,
    DISCOUNTCODE: null,
    DEVICEID: 144,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '59708978 702010 5',
    WARRANTYENDDATE: '2031-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2757,
    INVOICEDATE: '2023-11-29',
    DUEDATE: '2023-12-29',
    TOTALAMOUNT: 8.09,
    TAXAMOUNT: 0.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-12-13',
    PAYMENTMETHOD: 'Check'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 144,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Home Internet',
    SUBSCRIPTIONNAME: 'Fiber-optic',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 79.99,
    DISCOUNTCODE: null,
    DEVICEID: 144,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '59708978 702010 5',
    WARRANTYENDDATE: '2031-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2760,
    INVOICEDATE: '2023-12-29',
    DUEDATE: '2024-01-29',
    TOTALAMOUNT: 8.09,
    TAXAMOUNT: 0.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Due',
    PAYMENTDATE: '2024-01-07',
    PAYMENTMETHOD: null
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 144,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Home Internet',
    SUBSCRIPTIONNAME: 'Fiber-optic',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 79.99,
    DISCOUNTCODE: null,
    DEVICEID: 144,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '59708978 702010 5',
    WARRANTYENDDATE: '2031-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2763,
    INVOICEDATE: '2024-01-29',
    DUEDATE: '2024-02-29',
    TOTALAMOUNT: 8.09,
    TAXAMOUNT: 0.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Due',
    PAYMENTDATE: '2024-02-07',
    PAYMENTMETHOD: null
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 144,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Home Internet',
    SUBSCRIPTIONNAME: 'Fiber-optic',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 79.99,
    DISCOUNTCODE: null,
    DEVICEID: 144,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '59708978 702010 5',
    WARRANTYENDDATE: '2031-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2765,
    INVOICEDATE: '2024-02-29',
    DUEDATE: '2024-03-29',
    TOTALAMOUNT: 8.09,
    TAXAMOUNT: 0.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Due',
    PAYMENTDATE: null,
    PAYMENTMETHOD: null
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 149,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Cable/Satellite TV',
    SUBSCRIPTIONNAME: 'Expanded Cable',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 69.99,
    DISCOUNTCODE: null,
    DEVICEID: 149,
    MODEL: 'iPhone SE (3rd generation)',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Apple',
    IMEI: '96815087 961831 4',
    WARRANTYENDDATE: '2033-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2784,
    INVOICEDATE: '2023-05-29',
    DUEDATE: '2023-06-29',
    TOTALAMOUNT: 62.99,
    TAXAMOUNT: 7,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-06-04',
    PAYMENTMETHOD: 'Cash App'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 149,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Cable/Satellite TV',
    SUBSCRIPTIONNAME: 'Expanded Cable',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 69.99,
    DISCOUNTCODE: null,
    DEVICEID: 149,
    MODEL: 'iPhone SE (3rd generation)',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Apple',
    IMEI: '96815087 961831 4',
    WARRANTYENDDATE: '2033-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2788,
    INVOICEDATE: '2023-06-29',
    DUEDATE: '2023-07-29',
    TOTALAMOUNT: 62.99,
    TAXAMOUNT: 7,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-07-13',
    PAYMENTMETHOD: 'Mobile Wallet'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 149,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Cable/Satellite TV',
    SUBSCRIPTIONNAME: 'Expanded Cable',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 69.99,
    DISCOUNTCODE: null,
    DEVICEID: 149,
    MODEL: 'iPhone SE (3rd generation)',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Apple',
    IMEI: '96815087 961831 4',
    WARRANTYENDDATE: '2033-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2791,
    INVOICEDATE: '2023-07-29',
    DUEDATE: '2023-08-29',
    TOTALAMOUNT: 62.99,
    TAXAMOUNT: 7,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-08-05',
    PAYMENTMETHOD: 'Bank Transfer'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 149,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Cable/Satellite TV',
    SUBSCRIPTIONNAME: 'Expanded Cable',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 69.99,
    DISCOUNTCODE: null,
    DEVICEID: 149,
    MODEL: 'iPhone SE (3rd generation)',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Apple',
    IMEI: '96815087 961831 4',
    WARRANTYENDDATE: '2033-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2794,
    INVOICEDATE: '2023-08-29',
    DUEDATE: '2023-09-29',
    TOTALAMOUNT: 62.99,
    TAXAMOUNT: 7,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-09-13',
    PAYMENTMETHOD: 'Check'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 149,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Cable/Satellite TV',
    SUBSCRIPTIONNAME: 'Expanded Cable',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 69.99,
    DISCOUNTCODE: null,
    DEVICEID: 149,
    MODEL: 'iPhone SE (3rd generation)',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Apple',
    IMEI: '96815087 961831 4',
    WARRANTYENDDATE: '2033-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2796,
    INVOICEDATE: '2023-09-29',
    DUEDATE: '2023-10-29',
    TOTALAMOUNT: 62.99,
    TAXAMOUNT: 7,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-10-12',
    PAYMENTMETHOD: 'Cash App'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 149,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Cable/Satellite TV',
    SUBSCRIPTIONNAME: 'Expanded Cable',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 69.99,
    DISCOUNTCODE: null,
    DEVICEID: 149,
    MODEL: 'iPhone SE (3rd generation)',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Apple',
    IMEI: '96815087 961831 4',
    WARRANTYENDDATE: '2033-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2800,
    INVOICEDATE: '2023-10-29',
    DUEDATE: '2023-11-29',
    TOTALAMOUNT: 62.99,
    TAXAMOUNT: 7,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-10-30',
    PAYMENTMETHOD: 'Apple Pay'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 149,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Cable/Satellite TV',
    SUBSCRIPTIONNAME: 'Expanded Cable',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 69.99,
    DISCOUNTCODE: null,
    DEVICEID: 149,
    MODEL: 'iPhone SE (3rd generation)',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Apple',
    IMEI: '96815087 961831 4',
    WARRANTYENDDATE: '2033-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2803,
    INVOICEDATE: '2023-11-29',
    DUEDATE: '2023-12-29',
    TOTALAMOUNT: 62.99,
    TAXAMOUNT: 7,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-12-04',
    PAYMENTMETHOD: 'Square'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 149,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Cable/Satellite TV',
    SUBSCRIPTIONNAME: 'Expanded Cable',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 69.99,
    DISCOUNTCODE: null,
    DEVICEID: 149,
    MODEL: 'iPhone SE (3rd generation)',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Apple',
    IMEI: '96815087 961831 4',
    WARRANTYENDDATE: '2033-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2808,
    INVOICEDATE: '2023-12-29',
    DUEDATE: '2024-01-29',
    TOTALAMOUNT: 62.99,
    TAXAMOUNT: 7,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2024-01-03',
    PAYMENTMETHOD: 'Mobile Wallet'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 149,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Cable/Satellite TV',
    SUBSCRIPTIONNAME: 'Expanded Cable',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 69.99,
    DISCOUNTCODE: null,
    DEVICEID: 149,
    MODEL: 'iPhone SE (3rd generation)',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Apple',
    IMEI: '96815087 961831 4',
    WARRANTYENDDATE: '2033-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2810,
    INVOICEDATE: '2024-01-29',
    DUEDATE: '2024-02-29',
    TOTALAMOUNT: 62.99,
    TAXAMOUNT: 7,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2024-02-12',
    PAYMENTMETHOD: 'Google Pay'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 149,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Cable/Satellite TV',
    SUBSCRIPTIONNAME: 'Expanded Cable',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 69.99,
    DISCOUNTCODE: null,
    DEVICEID: 149,
    MODEL: 'iPhone SE (3rd generation)',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Apple',
    IMEI: '96815087 961831 4',
    WARRANTYENDDATE: '2033-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2812,
    INVOICEDATE: '2024-02-29',
    DUEDATE: '2024-03-29',
    TOTALAMOUNT: 62.99,
    TAXAMOUNT: 7,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Due',
    PAYMENTDATE: null,
    PAYMENTMETHOD: null
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 153,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Streaming Services',
    SUBSCRIPTIONNAME: 'Amazon Prime Video',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 18.99,
    DISCOUNTCODE: null,
    DEVICEID: 153,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '73052238 188450 4',
    WARRANTYENDDATE: '2029-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2823,
    INVOICEDATE: '2023-05-29',
    DUEDATE: '2023-06-29',
    TOTALAMOUNT: 17.09,
    TAXAMOUNT: 1.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-06-05',
    PAYMENTMETHOD: 'Venmo'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 153,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Streaming Services',
    SUBSCRIPTIONNAME: 'Amazon Prime Video',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 18.99,
    DISCOUNTCODE: null,
    DEVICEID: 153,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '73052238 188450 4',
    WARRANTYENDDATE: '2029-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2829,
    INVOICEDATE: '2023-06-29',
    DUEDATE: '2023-07-29',
    TOTALAMOUNT: 17.09,
    TAXAMOUNT: 1.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-07-14',
    PAYMENTMETHOD: 'Credit Card'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 153,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Streaming Services',
    SUBSCRIPTIONNAME: 'Amazon Prime Video',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 18.99,
    DISCOUNTCODE: null,
    DEVICEID: 153,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '73052238 188450 4',
    WARRANTYENDDATE: '2029-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2831,
    INVOICEDATE: '2023-07-29',
    DUEDATE: '2023-08-29',
    TOTALAMOUNT: 17.09,
    TAXAMOUNT: 1.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-08-13',
    PAYMENTMETHOD: 'Credit Card'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 153,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Streaming Services',
    SUBSCRIPTIONNAME: 'Amazon Prime Video',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 18.99,
    DISCOUNTCODE: null,
    DEVICEID: 153,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '73052238 188450 4',
    WARRANTYENDDATE: '2029-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2835,
    INVOICEDATE: '2023-08-29',
    DUEDATE: '2023-09-29',
    TOTALAMOUNT: 17.09,
    TAXAMOUNT: 1.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-09-02',
    PAYMENTMETHOD: 'Check'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 153,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Streaming Services',
    SUBSCRIPTIONNAME: 'Amazon Prime Video',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 18.99,
    DISCOUNTCODE: null,
    DEVICEID: 153,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '73052238 188450 4',
    WARRANTYENDDATE: '2029-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2838,
    INVOICEDATE: '2023-09-29',
    DUEDATE: '2023-10-29',
    TOTALAMOUNT: 17.09,
    TAXAMOUNT: 1.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-10-07',
    PAYMENTMETHOD: 'Mobile Wallet'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 153,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Streaming Services',
    SUBSCRIPTIONNAME: 'Amazon Prime Video',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 18.99,
    DISCOUNTCODE: null,
    DEVICEID: 153,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '73052238 188450 4',
    WARRANTYENDDATE: '2029-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2842,
    INVOICEDATE: '2023-10-29',
    DUEDATE: '2023-11-29',
    TOTALAMOUNT: 17.09,
    TAXAMOUNT: 1.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-11-07',
    PAYMENTMETHOD: 'Square'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 153,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Streaming Services',
    SUBSCRIPTIONNAME: 'Amazon Prime Video',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 18.99,
    DISCOUNTCODE: null,
    DEVICEID: 153,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '73052238 188450 4',
    WARRANTYENDDATE: '2029-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2846,
    INVOICEDATE: '2023-11-29',
    DUEDATE: '2023-12-29',
    TOTALAMOUNT: 17.09,
    TAXAMOUNT: 1.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2023-12-14',
    PAYMENTMETHOD: 'Credit Card'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 153,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Streaming Services',
    SUBSCRIPTIONNAME: 'Amazon Prime Video',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 18.99,
    DISCOUNTCODE: null,
    DEVICEID: 153,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '73052238 188450 4',
    WARRANTYENDDATE: '2029-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2850,
    INVOICEDATE: '2023-12-29',
    DUEDATE: '2024-01-29',
    TOTALAMOUNT: 17.09,
    TAXAMOUNT: 1.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2024-01-12',
    PAYMENTMETHOD: 'Klarna'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 153,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Streaming Services',
    SUBSCRIPTIONNAME: 'Amazon Prime Video',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 18.99,
    DISCOUNTCODE: null,
    DEVICEID: 153,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '73052238 188450 4',
    WARRANTYENDDATE: '2029-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2854,
    INVOICEDATE: '2024-01-29',
    DUEDATE: '2024-02-29',
    TOTALAMOUNT: 17.09,
    TAXAMOUNT: 1.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Paid',
    PAYMENTDATE: '2024-02-05',
    PAYMENTMETHOD: 'Direct Debit'
  },
  {
    CUSTOMERID: 1,
    FIRSTNAME: 'David',
    LASTNAME: 'Smith',
    CONTACTINFOID: 41,
    ADDRESSID: 41,
    DATEOFBIRTH: '2000-11-13',
    GENDER: 'Gender variant',
    NATIONALID: '622649337',
    REGISTRATIONDATE: '2023-04-29',
    SUBSCRIBERID: 153,
    SUBSCRIPTIONDATE: '2023-04-29',
    SUBSCRIPTIONTYPE: 'Streaming Services',
    SUBSCRIPTIONNAME: 'Amazon Prime Video',
    SUBSCRIPTIONSTATUS: 'Active',
    SERVICEACTIVATIONDATE: '2023-04-29',
    CONTRACTSTARTDATE: '2023-04-29',
    CONTRACTENDDATE: null,
    MONTHLYFEE: 18.99,
    DISCOUNTCODE: null,
    DEVICEID: 153,
    MODEL: 'BGW210-700',
    ACTIVATIONDATE: '2023-04-29',
    MANUFACTURER: 'Arris',
    IMEI: '73052238 188450 4',
    WARRANTYENDDATE: '2029-04-29',
    INSTALLATIONDATE: '2023-04-29',
    STATUS: 'Active',
    INVOICEID: 2856,
    INVOICEDATE: '2024-02-29',
    DUEDATE: '2024-03-29',
    TOTALAMOUNT: 17.09,
    TAXAMOUNT: 1.9,
    DISCOUNTAMOUNT: null,
    PAYMENTSTATUS: 'Due',
    PAYMENTDATE: null,
    PAYMENTMETHOD: null
  }
];

const GatheredDataView = ({ title, data, collapseIndex }) => {
  return (
    <div>
      {/* Title */}
      <h2 className="text-[#007BFF] font-bold border-b-2 border-[#007BFF] pb-1">{title}</h2>

      {/* JSON Data */}
      {/* <pre className="bg-gray-100 p-3 rounded-md text-sm max-w-full overflow-auto whitespace-pre-wrap break-words mt-3">
        {JSON.stringify(data, null, 2)}
      </pre> */}

      <ExpandableContent data={data} collapseIndex={collapseIndex} />

      {/* Summary Section */}
      <h3 className="font-bold text-gray-800 mt-3">Please summarize the data gathered.</h3>
      <p className="font-bold">This output is not for the customer. It is for the agent.</p>
    </div>
  );
};

export default GatheredDataView;
