'use client';
import React, { useMemo, useState, forwardRef, useEffect } from 'react';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { XIcon } from 'lucide-react';
import MarkDownComp from './MarkDown';
import Message from './Message2';
import BubbleHelp from './BubbleHelp';

const convertTabsToSpaces = (str, spaces = 4) => str.replace(/\t/g, ' '.repeat(spaces));

const Bubble = forwardRef(function Bubble(
  {
    message,
    status,
    divider,
    markdownDisabled,
    mainchat,
    flow,
    step,
    index,
    setItems,
    scrollToElement,
    getLastReflectionByIndex,
    getLastAnswerIndex,
    selectedFlow,
    message_id,
    isAnimatingChatLoad
  }: {
    message: {
      role: string;
      prompt: string;
      time?: string;
      prompt_history?: Array<{ content: string; type: string }>;
    };
    status?: string;
    divider?: boolean;
    markdownDisabled?: boolean;
    mainchat?: boolean;
    flow?: boolean;
    step?: number;
    index?: number;
    setItems: any;
    scrollToElement: any;
    getLastReflectionByIndex?: (index?: string) => void;
    getLastAnswerIndex?: (index?: string) => void;
    selectedFlow?: number;
    message_id?: string;
    isAnimatingChatLoad?: boolean;
  },
  ref: React.Ref<HTMLDivElement> // Forwarded ref type
) {
  const showHistoryButton = useMemo(
    () => Array.isArray(message.prompt_history) && message.prompt_history.length > 0,
    [message.prompt_history]
  );
  const [showHistory, setShowHistory] = useState(false);

  const isSelectedFlow = useMemo(() => selectedFlow === index, [index, selectedFlow]);

  return (
    <div
      className={`flex gap-3 my-1 text-gray-600 text-sm space-between w-full p-1 ${isSelectedFlow && 'border-l-[15px] border-blue-500'}`}
      ref={ref}>
      {showHistory && (
        <div className="fixed top-0 left-0 w-screen h-screen z-20 flex items-center justify-center inset-0 bg-black bg-opacity-80">
          <div className="bg-white rounded-lg shadow-2xl  h-[80%] w-[50%]">
            <div className="sticky left-0 top-0 w-full bg-slate-200 p-2 flex justify-between">
              <div className="font-bold ">chat history</div>
              <XIcon onClick={() => setShowHistory(false)} className="w-5 h-5 cursor-pointer" />
            </div>
            <div className="overflow-auto flex flex-col gap-2 justify-start items-center p-8 h-[80%]">
              {message.prompt_history?.map((elem, i) => (
                <Message content={elem.content} time={''} type={elem.type} />
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="w-full">
        {divider && (
          <div className="h-[2px] w-full border-t-0 shadow-sm bg-blue-500 mb-[15px]"></div>
        )}
        <Message
          markdownDisabled={markdownDisabled}
          content={message.prompt}
          time={message.time || ''}
          type={message.role}
          showHistoryButton={showHistoryButton}
          openHistory={() => setShowHistory(true)}
          mainchat={mainchat}
        />

        {flow && (
          <BubbleHelp
            scrollToElement={scrollToElement}
            isAnimatingChatLoad={isAnimatingChatLoad}
            step={step}
            index={index}
            setItems={setItems}
            getLastReflectionByIndex={getLastReflectionByIndex}
            getLastAnswerIndex={getLastAnswerIndex}
            message_id={message_id}
          />
        )}
      </div>
    </div>
  );
});

export default Bubble;
