import './App.css';
import React from 'react';
import Provider from './Provider';
import Page from './page';
import { BrowserRouter } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
function App() {
  return (
    <BrowserRouter>
      <Provider>
        <div className="App">
          <BrowserView>
            <Page />
          </BrowserView>
          <MobileView>
            <h1>Not supported on mobile...</h1>
          </MobileView>
        </div>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
