import React, { useState } from 'react';
import { githubLightTheme, JsonEditor } from 'json-edit-react';

const ArrayJsonViewer = ({ data, collapseIndex }: { data: Array<any>; collapseIndex: number }) => {
  // Expand only the first item
  const collapseCondition = ({ index }: { index: number }) => index > collapseIndex;

  return (
    <JsonEditor
      data={data}
      viewOnly={true}
      theme={githubLightTheme}
      collapse={collapseCondition}
      showCollectionCount={true}
      rootName="data"
      indent={6}
      //   theme={{
      //     styles: {
      //       container: { backgroundColor: '#f8f9fa' },
      //       bracket: { fontWeight: 'bold' }
      //     }
      //   }}
    />
  );
};

export default ArrayJsonViewer;
