'use client';
import React, { useEffect, useState } from 'react';

interface BubbleTextProps {
  step: any;
  scrollToElement: any;
  index: any;
  setItems: any;
  getLastReflectionByIndex?: (index?: string) => void;
  getLastAnswerIndex?: (index?: string) => void;
  message_id?: string;
  isAnimatingChatLoad?: boolean;
}

const BubbleText: React.FC<BubbleTextProps> = ({
  step,
  scrollToElement,
  index,
  setItems,
  getLastReflectionByIndex,
  getLastAnswerIndex,
  message_id,
  isAnimatingChatLoad
}) => {
  // const [isAnimating, setIsAnimating] = useState(false);

  const targetRefInChat = {
    0: 0,
    1: 1,
    2: 1,
    3: 3,
    4: 5,
    5: 10,
    6: getLastReflectionByIndex ? getLastReflectionByIndex(message_id) : -1,
    7: getLastAnswerIndex ? getLastAnswerIndex(message_id) : -1
  };

  const handleClick = (targetStep: number, offset: number) => {
    if (step === targetStep) {
      setItems((prevItems) =>
        prevItems.map((item, idx) => {
          if (idx === index) {
            return { ...item, isAnimatingChatLoad: true }; // Add or update isAnimating property
          }
          return item;
        })
      );
      setTimeout(() => {
        setItems((prevItems) =>
          prevItems.map((item, idx) => {
            if (idx === index) {
              // Update the step in the specific item
              return { ...item, step: targetStep + 1, isAnimatingChatLoad: false };
            }
            return item;
          })
        );
      }, 2000);
    }
    scrollToElement(targetRefInChat[targetStep], offset);
  };

  const [clickedLinks, setClickedLinks] = useState<{ [key: number]: boolean }>({});

  const handleColorBlack = (targetStep: number) => {
    setClickedLinks((prev) => ({
      ...prev,
      [targetStep]: true // Mark this step as clicked
    }));
  };

  return (
    <div className="flex flex-col gap-4 text-left">
      {/* STEP 0 */}
      {step >= 0 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          {/* Icon */}
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          {/* Bubble */}
          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md text-left p-4 pr-[20%]">
            <p>
              I was able to answer your question by retrieving your data (David Smith’s data...)
              from multiple backend systems into the chat context, using K2view’s semantic data
              layer and AI data agent framework.
            </p>
            <p>
              <span
                className={`font-bold cursor-pointer hover:underline ${
                  step > 0 ? 'text-black' : 'text-blue-600'
                }`}
                onClick={() => handleClick(0, -100)}>
                I can show and explain how that happened &gt;
              </span>
            </p>
            <p>Or you can ask me another question about your account...</p>
          </div>
        </div>
      )}

      {/* STEP 1 */}
      {step >= 1 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full text-left">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4 pr-[20%]">
            <p>
              The Trace panel on the right shows you the behind-the-scene interaction you just had
              with K2view’s AI data agent and the LLM.
            </p>
            <p>
              The AI data agent begins by providing the LLM with initial prompts to establish the
              chatbot’s persona, share useful information about Elite’s customer service, and
              outline the steps the AI agent should follow to assist you.
            </p>
            <p>
              <span
                className={`font-bold cursor-pointer hover:underline ${
                  step > 1 ? 'text-black' : 'text-blue-600'
                }`}
                onClick={() => handleClick(1, -100)}>
                Let’s see how real-time data was retrieved to answer your question &gt;
              </span>
            </p>
            <p>Or you can ask me another question...</p>
          </div>
        </div>
      )}

      {/* STEP 2 */}
      {step >= 2 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4 pr-[20%]">
            <p>
              K2view provides a semantic data layer optimized for conversational GenAI, enabling the
              K2view AI data agent to securely retrieve data in real time, from any number of
              backend sources.
            </p>
            <p>
              In this demo environment, the semantic layer is preconfigured to provide instant and
              guardrailed access to the operational data of the customer in session. This means that
              the AI data agent can’t access the data for any other customer.
            </p>
            <p>
              In the Trace panel on the right you can see the semantic data layer’s schema,
              retrieved by the AI data agent. The metadata is context-rich, allowing the LLM to
              convert your questions into SQL statements and answer them based on the retrieved
              data.
            </p>
            <span
              className={`font-bold cursor-pointer hover:underline ${
                step > 2 ? 'text-black' : 'text-blue-600'
              }`}
              onClick={() => handleClick(2, 1300)}>
              See how user prompts are converted into SQL queries &gt;
            </span>
            <p>Or ask me another question about your account...</p>
          </div>
        </div>
      )}

      {/* STEP 3 */}
      {step >= 3 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4 pr-[20%]">
            <p>
              As you can see, the Micro Database schema is well annotated. It is descriptive enough
              to empower GenAI to understand the customer model. In fact Micro Databases are the
              ideal data source for GenAI
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>
                <strong>Fast and Scalable</strong> – Micro Databases are small and isolated so query
                time is blazing fast.
              </li>
              <li>
                <strong>Fresh</strong> – data is always synchronized using an array of techniques
                such as Just in Time querying, push/pull, batch. Since we only need to sync the
                active micro database, there is little stress on your existing datasources.
              </li>
              <li>
                <strong>Secure</strong> – Data is contextualized by a business entity so GenAI only
                sees relevant information. The segregation between micro databases make it
                impossible for the LLM to access data outside the given customer.
              </li>
            </ul>
            <span
              className={`font-bold cursor-pointer hover:underline ${
                step > 3 ? 'text-black' : 'text-blue-600'
              }`}
              onClick={() => handleClick(3, -100)}>
              How is data queried &gt;
            </span>
            <p></p>
          </div>
        </div>
      )}

      {/* STEP 4 */}
      {step >= 4 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4 pr-[20%]">
            <p>
              It's practically impossible to develop an LLM function for every question that a user
              might ask. We’d have to create hundreds of such functions!
            </p>
            <p>
              Instead of trying to anticipate every question, K2view enables the LLM to generate a
              SQL query based on the user’s prompt and the semantic layer’s metadata.
            </p>
            <p>
              In the Trace panel, we can see the LLM-crafted query which will return relevant data
              about outages and invoices.
            </p>
            <span
              className={`font-bold cursor-pointer hover:underline ${
                step > 4 ? 'text-black' : 'text-blue-600'
              }`}
              onClick={() => handleClick(4, -100)}>
              I can show you the query results &gt;
            </span>
            <p>Or you can continue our chat with another question...</p>
          </div>
        </div>
      )}

      {/* STEP 5 */}
      {step >= 5 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4 pr-[20%]">
            <p>
              The K2view AI data agent executes the query to retrieve fresh customer data, directly
              from 2 back-end systems, via the K2view semantic data layer.
            </p>
            <p>
              Query response time is milliseconds, providing the user with a conversational
              experience.
            </p>
            <span
              className={`font-bold cursor-pointer hover:underline ${
                step > 5 ? 'text-black' : 'text-blue-600'
              }`}
              onClick={() => handleClick(5, -100)}>
              See how the query results are summarized into human readable format &gt;
            </span>
          </div>
        </div>
      )}

      {/* STEP 6 */}
      {step >= 6 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4 pr-[20%]">
            <p>
              The K2view AI data agent compiles the query results and enriches them with the
              necessary business context, enabling the Elite Assistant to craft tailored responses
              to your questions.
            </p>
            <span
              className={`font-bold cursor-pointer hover:underline ${
                step > 6 ? 'text-black' : 'text-blue-600'
              }`}
              onClick={() => handleClick(6, -100)}>
              Learn how LLM reflection is applied &gt;
            </span>
          </div>
        </div>
      )}

      {/* STEP 7 */}
      {step >= 7 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4 pr-[20%]">
            <p>
              At the heart of the K2view AI data agent framework lies the concept of LLM reflection.
              Using the information gathered by the data agent, the LLM is prompted to assess
              whether sufficient data is available to answer the user’s question.
            </p>
            <p>
              In our case, the data agent asks the LLM if additional data is needed from the backend
              systems, if all the necessary information is at hand, or if further input is required
              from the user. The reflection loop repeats this until the LLM confirms that we’re
              ready to formulate the answer.
            </p>
            <span
              className={`font-bold cursor-pointer hover:underline ${
                step > 7 ? 'text-black' : 'text-blue-600'
              }`}
              onClick={() => handleClick(7, -100)}>
              Final step: Generate the answer! &gt;
            </span>
          </div>
        </div>
      )}

      {/* STEP 8 */}
      {step >= 8 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4 pr-[20%]">
            <p>
              The AI data agent is now prepared to generate an answer. Using all the information
              gathered so far and the full conversation context, the LLM is prompted to craft a
              response.
            </p>
            <p>
              This response was generated by harnessing the power of the LLM, while being firmly
              grounded in fresh customer data that was dynamically retrieved via the K2view semantic
              data layer.
              <br />
            </p>
            <span
              className={`font-bold cursor-pointer hover:underline ${
                step > 8 ? 'text-black' : 'text-blue-600'
              }`}
              onClick={() => handleClick(8, -100)}>
              Summary: from your question to the LLM answer &gt;
            </span>
          </div>
        </div>
      )}

      {/* STEP 9 */}
      {step >= 9 && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          <div className="w-8 h-8">
            <div className="rounded-full bg-gray-100 border p-1">
              <svg
                stroke="none"
                fill="black"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"></path>
              </svg>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-2 mt-[5px] bg-[#e2f1ff] rounded-md p-4 pr-[20%]">
            <p>
              Thank you for exploring K2view GenAI Data Fusion! This live demo showcased how we fuse
              enterprise data with GenAI to deliver personalized and accurate responses your users
              will trust.
            </p>
            <p>Here's a recap of the key platform capabilities:</p>
            <ul className="list-disc list-inside ml-4">
              <div
                className={`font-bold cursor-pointer hover:underline ${
                  clickedLinks[2] ? 'text-black' : 'text-blue-600'
                }`}
                onClick={() => {
                  handleColorBlack(2);
                  handleClick(2, 1300);
                }}>
                The Semantic Data Layer, optimized for GenAI &gt;
              </div>

              <div
                className={`font-bold cursor-pointer hover:underline ${
                  clickedLinks[6] ? 'text-black' : 'text-blue-600'
                }`}
                onClick={() => {
                  handleColorBlack(6);
                  handleClick(6, -100);
                }}>
                LLM Reflection for generating accurate LLM responses &gt;
              </div>

              <div
                className={`font-bold cursor-pointer hover:underline ${
                  clickedLinks[3] ? 'text-black' : 'text-blue-600'
                }`}
                onClick={() => {
                  handleColorBlack(3);
                  handleClick(3, -100);
                }}>
                Text-to-SQL automation &gt;
              </div>

              <div
                className={`font-bold cursor-pointer hover:underline ${
                  clickedLinks[4] ? 'text-black' : 'text-blue-600'
                }`}
                onClick={() => {
                  handleColorBlack(4);
                  handleClick(4, -100);
                }}>
                Turning data to information &gt;
              </div>
            </ul>
            <p>
              The AI data agent can be extended to analyze the customer sentiment, integrate
              interaction outcomes into your CRM application, generate personalized follow-up
              emails, and more.
            </p>
            <p>
              If you’re interested in learning more about our AI data agent framework and the
              intuitive, codeless tools that power it, we’d love to connect live.
            </p>
            <span
              className="font-bold text-blue-600 cursor-pointer hover:underline"
              onClick={() => handleClick(8, 0)}>
              <p>
                <a
                  href={sessionStorage.getItem('fullURL') ?? ''}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="h-[32px] mt-[1px] rounded-[25px] border border-[#fbfbfb] bg-[#fbfbfb] flex items-center justify-center p-3 hover:ring-2 hover:border-black">
                  <span className="font-roboto text-[16px] font-medium text-center text-black ">
                    <strong>Schedule a meeting with a product expert &gt;</strong>
                  </span>
                </a>
              </p>
            </span>
          </div>
        </div>
      )}

      {isAnimatingChatLoad && (
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          {/* Icon */}
          <div className="w-8 h-8 p1"></div>

          <div className="relative w-16 h-8 bg-gray-200 rounded-full flex items-center justify-center">
            <div className="absolute bottom-0 left-2 w-4 h-4 bg-gray-200 rotate-45"></div>

            <div className="flex space-x-1">
              <div className="w-2 h-2 bg-gray-400 rounded-full animate-pulse"></div>
              <div className="w-2 h-2 bg-gray-400 rounded-full animate-pulse delay-100"></div>
              <div className="w-2 h-2 bg-gray-400 rounded-full animate-pulse delay-200"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BubbleText;
